import GATSBY_COMPILED_MDX from "/opt/build/repo/content/insights/creating-blog-with-gatsby/creating-blog-with-gatsby.mdx";
import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import {Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import {SEO} from '../components/SEO';
var shortcodes = {
  Link: Link
};
function PageTemplate(_ref) {
  var _ref$data$mdx = _ref.data.mdx, _ref$data$mdx$frontma = _ref$data$mdx.frontmatter, title = _ref$data$mdx$frontma.title, imagePath = _ref$data$mdx$frontma.imagePath, date = _ref$data$mdx$frontma.date, minutes = _ref$data$mdx.fields.timeToRead.minutes, children = _ref.children;
  var mainImage = getImage(imagePath);
  return React.createElement(Layout, null, React.createElement("div", {
    className: "p-4 lg:p-10 flex flex-wrap max-w-6xl"
  }, React.createElement("div", {
    className: "w-full lg:w-1/3 lg:pr-16 pr-0"
  }, React.createElement("h1", {
    className: "text-4xl mb-8 lg:mt-0 mt-6"
  }, title), mainImage && React.createElement(GatsbyImage, {
    className: "h-80 object-cover w-full",
    image: mainImage,
    alt: "Header"
  })), React.createElement("div", {
    className: "w-full lg:w-2/3 lg:pt-0 pt-6"
  }, React.createElement("div", {
    className: "mb-8 leading-5"
  }, React.createElement("p", null, date), React.createElement("p", {
    className: "opacity-50"
  }, Math.round(minutes), " minute read")), React.createElement("article", {
    className: "prose prose-headings:font-normal prose-p:font-serif dark:prose-invert prose-li:font-serif"
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children)))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head(_ref2) {
  var data = _ref2.data;
  return React.createElement(SEO, {
    title: data.mdx.frontmatter.title
  });
}
var query = "2478157266";
